.overlay {
  position: fixed ;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 181, 80, 0.6);
  z-index: 99;
}
.can-btn {
  color: #fff;
  width: 100px;
  height: 40px;
  background-color: #000;
}
.failed-overlay {
  position: fixed ;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(181, 18, 18, 0.6);
  z-index: 99;
}

.approval-text {
  /* margin: auto; */
  color: white;
  text-align: center;
  line-height: 2;
}
@media (max-width: 768px) {
  .cMFuDJ {
    max-width: 800px;
    grid-template-columns: 1fr;
  }
}

.hKhOIm {
  transition: background-color 0.2s ease-in-out 0s;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  padding: 24px 16px;
}

.cMFuDJ {
  position: relative;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  margin: 10px;
  padding: 0px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  /* display: grid; */
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  max-width: 800px;
  min-width: fit-content;
  max-height: 100%;
  overflow: auto;
}

.bElhDP {
  width: 100%;
  padding: 8px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 0px;
  border: 1px solid rgba(195, 195, 195, 0.14);
}

.fqonLZ {
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 50%;
  overflow: visible;
  box-shadow: none;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.fqonLZ img {
  width: 100%;
  height: 100%;
}

.gHoDBx {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  margin-top: 0.5em;
  color: rgb(12, 12, 13);
}

.eCZoDi {
  width: 100%;
  font-size: 18px;
  margin: 0.333em 0px;
  color: rgb(169, 169, 188);
}








.anchor.before {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  padding-right: 4px;
}

.anchor.after {
  display: inline-block;
  padding-left: 4px;
}

h1 .anchor svg,
h2 .anchor svg,
h3 .anchor svg,
h4 .anchor svg,
h5 .anchor svg,
h6 .anchor svg {
  visibility: hidden;
}

h1:hover .anchor svg,
h2:hover .anchor svg,
h3:hover .anchor svg,
h4:hover .anchor svg,
h5:hover .anchor svg,
h6:hover .anchor svg,
h1 .anchor:focus svg,
h2 .anchor:focus svg,
h3 .anchor:focus svg,
h4 .anchor:focus svg,
h5 .anchor:focus svg,
h6 .anchor:focus svg {
  visibility: visible;
}


.header-anchor.before {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  padding-right: 4px;
}

.header-anchor.after {
  display: inline-block;
  padding-left: 4px;
}

h1 .header-anchor svg,
h2 .header-anchor svg,
h3 .header-anchor svg,
h4 .header-anchor svg,
h5 .header-anchor svg,
h6 .header-anchor svg {
  visibility: hidden;
}

h1:hover .header-anchor svg,
h2:hover .header-anchor svg,
h3:hover .header-anchor svg,
h4:hover .header-anchor svg,
h5:hover .header-anchor svg,
h6:hover .header-anchor svg,
h1 .header-anchor:focus svg,
h2 .header-anchor:focus svg,
h3 .header-anchor:focus svg,
h4 .header-anchor:focus svg,
h5 .header-anchor:focus svg,
h6 .header-anchor:focus svg {
  visibility: visible;
}


.gatsby-image-wrapper {
  position: relative;
  overflow: hidden
}

.gatsby-image-wrapper picture.object-fit-polyfill {
  position: static !important
}

.gatsby-image-wrapper img {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover
}

.gatsby-image-wrapper [data-main-image] {
  opacity: 0;
  transform: translateZ(0);
  transition: opacity .25s linear;
  will-change: opacity
}

.gatsby-image-wrapper-constrained {
  display: inline-block;
  vertical-align: top
}


.gatsby-image-wrapper noscript [data-main-image] {
  opacity: 1 !important
}

.gatsby-image-wrapper [data-placeholder-image] {
  opacity: 0 !important
}


:host,
:root,
[data-theme] {
  --chakra-ring-inset: var(--chakra-empty,
      /*!*/
      /*!*/
    );
  --chakra-ring-offset-width: 0px;
  --chakra-ring-offset-color: #fff;
  --chakra-ring-color: rgba(66, 153, 225, 0.6);
  --chakra-ring-offset-shadow: 0 0 #0000;
  --chakra-ring-shadow: 0 0 #0000;
  --chakra-space-x-reverse: 0;
  --chakra-space-y-reverse: 0;
  --eth-colors-transparent: transparent;
  --eth-colors-current: currentColor;
  --eth-colors-whiteAlpha-50: rgba(255, 255, 255, 0.04);
  --eth-colors-whiteAlpha-100: rgba(255, 255, 255, 0.06);
  --eth-colors-whiteAlpha-200: rgba(255, 255, 255, 0.08);
  --eth-colors-whiteAlpha-300: rgba(255, 255, 255, 0.16);
  --eth-colors-whiteAlpha-400: rgba(255, 255, 255, 0.24);
  --eth-colors-whiteAlpha-500: rgba(255, 255, 255, 0.36);
  --eth-colors-whiteAlpha-600: rgba(255, 255, 255, 0.48);
  --eth-colors-whiteAlpha-700: rgba(255, 255, 255, 0.64);
  --eth-colors-whiteAlpha-800: rgba(255, 255, 255, 0.80);
  --eth-colors-whiteAlpha-900: rgba(255, 255, 255, 0.92);
  --eth-colors-blackAlpha-50: rgba(0, 0, 0, 0.04);
  --eth-colors-blackAlpha-100: rgba(0, 0, 0, 0.06);
  --eth-colors-blackAlpha-200: rgba(0, 0, 0, 0.08);
  --eth-colors-blackAlpha-300: rgba(0, 0, 0, 0.16);
  --eth-colors-blackAlpha-400: rgba(0, 0, 0, 0.24);
  --eth-colors-blackAlpha-500: rgba(0, 0, 0, 0.36);
  --eth-colors-blackAlpha-600: rgba(0, 0, 0, 0.48);
  --eth-colors-blackAlpha-700: rgba(0, 0, 0, 0.64);
  --eth-colors-blackAlpha-800: rgba(0, 0, 0, 0.80);
  --eth-colors-blackAlpha-900: rgba(0, 0, 0, 0.92);
  --eth-colors-gray-50: #F7FAFC;
  --eth-colors-gray-100: #f7f7f7;
  --eth-colors-gray-200: #E2E8F0;
  --eth-colors-gray-300: #d4d4d4;
  --eth-colors-gray-400: #b0b0b0;
  --eth-colors-gray-500: #646464;
  --eth-colors-gray-600: #4A5568;
  --eth-colors-gray-700: #222222;
  --eth-colors-gray-800: #1A202C;
  --eth-colors-gray-900: #141414;
  --eth-colors-red-50: #FFF5F5;
  --eth-colors-red-100: #FED7D7;
  --eth-colors-red-200: #FEB2B2;
  --eth-colors-red-300: #FC8181;
  --eth-colors-red-400: #F56565;
  --eth-colors-red-500: #b80000;
  --eth-colors-red-600: #C53030;
  --eth-colors-red-700: #9B2C2C;
  --eth-colors-red-800: #822727;
  --eth-colors-red-900: #63171B;
  --eth-colors-orange-50: #FFFAF0;
  --eth-colors-orange-100: #ffe3d3;
  --eth-colors-orange-200: #FBD38D;
  --eth-colors-orange-300: #ffb991;
  --eth-colors-orange-400: #ED8936;
  --eth-colors-orange-500: #ff7324;
  --eth-colors-orange-600: #C05621;
  --eth-colors-orange-700: #9C4221;
  --eth-colors-orange-800: #451900;
  --eth-colors-orange-900: #652B19;
  --eth-colors-yellow-50: #FFFFF0;
  --eth-colors-yellow-100: #FEFCBF;
  --eth-colors-yellow-200: #fff8df;
  --eth-colors-yellow-300: #F6E05E;
  --eth-colors-yellow-400: #ECC94B;
  --eth-colors-yellow-500: #D69E2E;
  --eth-colors-yellow-600: #B7791F;
  --eth-colors-yellow-700: #975A16;
  --eth-colors-yellow-800: #744210;
  --eth-colors-yellow-900: #5F370E;
  --eth-colors-green-50: #F0FFF4;
  --eth-colors-green-100: #C6F6D5;
  --eth-colors-green-200: #9AE6B4;
  --eth-colors-green-300: #68D391;
  --eth-colors-green-400: #48BB78;
  --eth-colors-green-500: #109e62;
  --eth-colors-green-600: #2F855A;
  --eth-colors-green-700: #276749;
  --eth-colors-green-800: #22543D;
  --eth-colors-green-900: #1C4532;
  --eth-colors-teal-50: #E6FFFA;
  --eth-colors-teal-100: #B2F5EA;
  --eth-colors-teal-200: #81E6D9;
  --eth-colors-teal-300: #4FD1C5;
  --eth-colors-teal-400: #38B2AC;
  --eth-colors-teal-500: #319795;
  --eth-colors-teal-600: #2C7A7B;
  --eth-colors-teal-700: #285E61;
  --eth-colors-teal-800: #234E52;
  --eth-colors-teal-900: #1D4044;
  --eth-colors-blue-50: #ebf8ff;
  --eth-colors-blue-100: #e8e8ff;
  --eth-colors-blue-200: #90cdf4;
  --eth-colors-blue-300: #8d8dff;
  --eth-colors-blue-400: #4299e1;
  --eth-colors-blue-500: #1c1cff;
  --eth-colors-blue-600: #2b6cb0;
  --eth-colors-blue-700: #0b0b66;
  --eth-colors-blue-800: #2a4365;
  --eth-colors-blue-900: #1A365D;
  --eth-colors-cyan-50: #EDFDFD;
  --eth-colors-cyan-100: #C4F1F9;
  --eth-colors-cyan-200: #9DECF9;
  --eth-colors-cyan-300: #76E4F7;
  --eth-colors-cyan-400: #0BC5EA;
  --eth-colors-cyan-500: #00B5D8;
  --eth-colors-cyan-600: #00A3C4;
  --eth-colors-cyan-700: #0987A0;
  --eth-colors-cyan-800: #086F83;
  --eth-colors-cyan-900: #065666;
  --eth-colors-purple-50: #FAF5FF;
  --eth-colors-purple-100: #E9D8FD;
  --eth-colors-purple-200: #D6BCFA;
  --eth-colors-purple-300: #B794F4;
  --eth-colors-purple-400: #9F7AEA;
  --eth-colors-purple-500: #805AD5;
  --eth-colors-purple-600: #6B46C1;
  --eth-colors-purple-700: #553C9A;
  --eth-colors-purple-800: #44337A;
  --eth-colors-purple-900: #322659;
  --eth-colors-pink-50: #FFF5F7;
  --eth-colors-pink-100: #FED7E2;
  --eth-colors-pink-200: #FBB6CE;
  --eth-colors-pink-300: #F687B3;
  --eth-colors-pink-400: #ED64A6;
  --eth-colors-pink-500: #D53F8C;
  --eth-colors-pink-600: #B83280;
  --eth-colors-pink-700: #97266D;
  --eth-colors-pink-800: #702459;
  --eth-colors-pink-900: #521B41;
  --eth-colors-linkedin-50: #E8F4F9;
  --eth-colors-linkedin-100: #CFEDFB;
  --eth-colors-linkedin-200: #9BDAF3;
  --eth-colors-linkedin-300: #68C7EC;
  --eth-colors-linkedin-400: #34B3E4;
  --eth-colors-linkedin-500: #00A0DC;
  --eth-colors-linkedin-600: #008CC9;
  --eth-colors-linkedin-700: #0077B5;
  --eth-colors-linkedin-800: #005E93;
  --eth-colors-linkedin-900: #004471;
  --eth-colors-facebook-50: #E8F4F9;
  --eth-colors-facebook-100: #D9DEE9;
  --eth-colors-facebook-200: #B7C2DA;
  --eth-colors-facebook-300: #6482C0;
  --eth-colors-facebook-400: #4267B2;
  --eth-colors-facebook-500: #385898;
  --eth-colors-facebook-600: #314E89;
  --eth-colors-facebook-700: #29487D;
  --eth-colors-facebook-800: #223B67;
  --eth-colors-facebook-900: #1E355B;
  --eth-colors-messenger-50: #D0E6FF;
  --eth-colors-messenger-100: #B9DAFF;
  --eth-colors-messenger-200: #A2CDFF;
  --eth-colors-messenger-300: #7AB8FF;
  --eth-colors-messenger-400: #2E90FF;
  --eth-colors-messenger-500: #0078FF;
  --eth-colors-messenger-600: #0063D1;
  --eth-colors-messenger-700: #0052AC;
  --eth-colors-messenger-800: #003C7E;
  --eth-colors-messenger-900: #002C5C;
  --eth-colors-whatsapp-50: #dffeec;
  --eth-colors-whatsapp-100: #b9f5d0;
  --eth-colors-whatsapp-200: #90edb3;
  --eth-colors-whatsapp-300: #65e495;
  --eth-colors-whatsapp-400: #3cdd78;
  --eth-colors-whatsapp-500: #22c35e;
  --eth-colors-whatsapp-600: #179848;
  --eth-colors-whatsapp-700: #0c6c33;
  --eth-colors-whatsapp-800: #01421c;
  --eth-colors-whatsapp-900: #001803;
  --eth-colors-twitter-50: #E5F4FD;
  --eth-colors-twitter-100: #C8E9FB;
  --eth-colors-twitter-200: #A8DCFA;
  --eth-colors-twitter-300: #83CDF7;
  --eth-colors-twitter-400: #57BBF5;
  --eth-colors-twitter-500: #1DA1F2;
  --eth-colors-twitter-600: #1A94DA;
  --eth-colors-twitter-700: #1681BF;
  --eth-colors-twitter-800: #136B9E;
  --eth-colors-twitter-900: #0D4D71;
  --eth-colors-telegram-50: #E3F2F9;
  --eth-colors-telegram-100: #C5E4F3;
  --eth-colors-telegram-200: #A2D4EC;
  --eth-colors-telegram-300: #7AC1E4;
  --eth-colors-telegram-400: #47A9DA;
  --eth-colors-telegram-500: #0088CC;
  --eth-colors-telegram-600: #007AB8;
  --eth-colors-telegram-700: #006BA1;
  --eth-colors-telegram-800: #005885;
  --eth-colors-telegram-900: #003F5E;
  --eth-borders-none: 0;
  --eth-borders-1px: 1px solid;
  --eth-borders-2px: 2px solid;
  --eth-borders-4px: 4px solid;
  --eth-borders-8px: 8px solid;
  --eth-fonts-heading: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  --eth-fonts-body: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  --eth-fonts-mono: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --eth-fontSizes-xs: 0.75rem;
  --eth-fontSizes-sm: 0.875rem;
  --eth-fontSizes-md: 1rem;
  --eth-fontSizes-lg: 1.125rem;
  --eth-fontSizes-xl: 1.25rem;
  --eth-fontSizes-2xl: 1.5rem;
  --eth-fontSizes-3xl: 1.875rem;
  --eth-fontSizes-4xl: 2.25rem;
  --eth-fontSizes-5xl: 3rem;
  --eth-fontSizes-6xl: 3.75rem;
  --eth-fontSizes-7xl: 4.5rem;
  --eth-fontSizes-8xl: 6rem;
  --eth-fontSizes-9xl: 8rem;
  --eth-fontWeights-hairline: 100;
  --eth-fontWeights-thin: 200;
  --eth-fontWeights-light: 300;
  --eth-fontWeights-normal: 400;
  --eth-fontWeights-medium: 500;
  --eth-fontWeights-semibold: 600;
  --eth-fontWeights-bold: 700;
  --eth-fontWeights-extrabold: 800;
  --eth-fontWeights-black: 900;
  --eth-letterSpacings-tighter: -0.05em;
  --eth-letterSpacings-tight: -0.025em;
  --eth-letterSpacings-normal: 0;
  --eth-letterSpacings-wide: 0.025em;
  --eth-letterSpacings-wider: 0.05em;
  --eth-letterSpacings-widest: 0.1em;
  --eth-lineHeights-3: .75rem;
  --eth-lineHeights-4: 1rem;
  --eth-lineHeights-5: 1.25rem;
  --eth-lineHeights-6: 1.5rem;
  --eth-lineHeights-7: 1.75rem;
  --eth-lineHeights-8: 2rem;
  --eth-lineHeights-9: 2.25rem;
  --eth-lineHeights-10: 2.5rem;
  --eth-lineHeights-normal: normal;
  --eth-lineHeights-none: 1;
  --eth-lineHeights-shorter: 1.25;
  --eth-lineHeights-short: 1.375;
  --eth-lineHeights-base: 1.5;
  --eth-lineHeights-tall: 1.625;
  --eth-lineHeights-taller: 2;
  --eth-radii-none: 0;
  --eth-radii-sm: 0.125rem;
  --eth-radii-base: 0.25rem;
  --eth-radii-md: 0.375rem;
  --eth-radii-lg: 0.5rem;
  --eth-radii-xl: 0.75rem;
  --eth-radii-2xl: 1rem;
  --eth-radii-3xl: 1.5rem;
  --eth-radii-full: 9999px;
  --eth-space-1: 0.25rem;
  --eth-space-2: 0.5rem;
  --eth-space-3: 0.75rem;
  --eth-space-4: 1rem;
  --eth-space-5: 1.25rem;
  --eth-space-6: 1.5rem;
  --eth-space-7: 1.75rem;
  --eth-space-8: 2rem;
  --eth-space-9: 2.25rem;
  --eth-space-10: 2.5rem;
  --eth-space-12: 3rem;
  --eth-space-14: 3.5rem;
  --eth-space-16: 4rem;
  --eth-space-20: 5rem;
  --eth-space-24: 6rem;
  --eth-space-28: 7rem;
  --eth-space-32: 8rem;
  --eth-space-36: 9rem;
  --eth-space-40: 10rem;
  --eth-space-44: 11rem;
  --eth-space-48: 12rem;
  --eth-space-52: 13rem;
  --eth-space-56: 14rem;
  --eth-space-60: 15rem;
  --eth-space-64: 16rem;
  --eth-space-72: 18rem;
  --eth-space-80: 20rem;
  --eth-space-96: 24rem;
  --eth-space-px: 1px;
  --eth-space-0-5: 0.125rem;
  --eth-space-1-5: 0.375rem;
  --eth-space-2-5: 0.625rem;
  --eth-space-3-5: 0.875rem;
  --eth-shadows-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
  --eth-shadows-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --eth-shadows-base: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --eth-shadows-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --eth-shadows-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --eth-shadows-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --eth-shadows-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --eth-shadows-outline: 0 0 0 4px var(--eth-colors-primaryHover);
  --eth-shadows-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  --eth-shadows-none: none;
  --eth-shadows-dark-lg: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px;
  --eth-shadows-primary: 4px 4px 0px 0px var(--eth-colors-primaryLight);
  --eth-shadows-table: 0 14px 66px rgba(0, 0, 0, .07), 0 10px 17px rgba(0, 0, 0, .03), 0 4px 7px rgba(0, 0, 0, .05);
  --eth-sizes-1: 0.25rem;
  --eth-sizes-2: 0.5rem;
  --eth-sizes-3: 0.75rem;
  --eth-sizes-4: 1rem;
  --eth-sizes-5: 1.25rem;
  --eth-sizes-6: 1.5rem;
  --eth-sizes-7: 1.75rem;
  --eth-sizes-8: 2rem;
  --eth-sizes-9: 2.25rem;
  --eth-sizes-10: 2.5rem;
  --eth-sizes-12: 3rem;
  --eth-sizes-14: 3.5rem;
  --eth-sizes-16: 4rem;
  --eth-sizes-20: 5rem;
  --eth-sizes-24: 6rem;
  --eth-sizes-28: 7rem;
  --eth-sizes-32: 8rem;
  --eth-sizes-36: 9rem;
  --eth-sizes-40: 10rem;
  --eth-sizes-44: 11rem;
  --eth-sizes-48: 12rem;
  --eth-sizes-52: 13rem;
  --eth-sizes-56: 14rem;
  --eth-sizes-60: 15rem;
  --eth-sizes-64: 16rem;
  --eth-sizes-72: 18rem;
  --eth-sizes-80: 20rem;
  --eth-sizes-96: 24rem;
  --eth-sizes-px: 1px;
  --eth-sizes-0-5: 0.125rem;
  --eth-sizes-1-5: 0.375rem;
  --eth-sizes-2-5: 0.625rem;
  --eth-sizes-3-5: 0.875rem;
  --eth-sizes-max: max-content;
  --eth-sizes-min: min-content;
  --eth-sizes-full: 100%;
  --eth-sizes-3xs: 14rem;
  --eth-sizes-2xs: 16rem;
  --eth-sizes-xs: 20rem;
  --eth-sizes-sm: 24rem;
  --eth-sizes-md: 28rem;
  --eth-sizes-lg: 32rem;
  --eth-sizes-xl: 36rem;
  --eth-sizes-2xl: 42rem;
  --eth-sizes-3xl: 48rem;
  --eth-sizes-4xl: 56rem;
  --eth-sizes-5xl: 64rem;
  --eth-sizes-6xl: 72rem;
  --eth-sizes-7xl: 80rem;
  --eth-sizes-8xl: 90rem;
  --eth-sizes-container-sm: 640px;
  --eth-sizes-container-md: 768px;
  --eth-sizes-container-lg: 1024px;
  --eth-sizes-container-xl: 1280px;
  --eth-zIndices-hide: -1;
  --eth-zIndices-auto: auto;
  --eth-zIndices-base: 0;
  --eth-zIndices-docked: 10;
  --eth-zIndices-dropdown: 1000;
  --eth-zIndices-sticky: 1100;
  --eth-zIndices-banner: 1200;
  --eth-zIndices-overlay: 1300;
  --eth-zIndices-modal: 1400;
  --eth-zIndices-popover: 1500;
  --eth-zIndices-skipLink: 1600;
  --eth-zIndices-toast: 1700;
  --eth-zIndices-tooltip: 1800;
  --eth-transition-property-common: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  --eth-transition-property-colors: background-color, border-color, color, fill, stroke;
  --eth-transition-property-dimensions: width, height;
  --eth-transition-property-position: left, right, top, bottom;
  --eth-transition-property-background: background-color, background-image, background-position;
  --eth-transition-easing-ease-in: cubic-bezier(0.4, 0, 1, 1);
  --eth-transition-easing-ease-out: cubic-bezier(0, 0, 0.2, 1);
  --eth-transition-easing-ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --eth-transition-duration-ultra-fast: 50ms;
  --eth-transition-duration-faster: 100ms;
  --eth-transition-duration-fast: 150ms;
  --eth-transition-duration-normal: 200ms;
  --eth-transition-duration-slow: 300ms;
  --eth-transition-duration-slower: 400ms;
  --eth-transition-duration-ultra-slow: 500ms;
  --eth-blur-none: 0;
  --eth-blur-sm: 4px;
  --eth-blur-base: 8px;
  --eth-blur-md: 12px;
  --eth-blur-lg: 16px;
  --eth-blur-xl: 24px;
  --eth-blur-2xl: 40px;
  --eth-blur-3xl: 64px;
  --eth-colors-success: var(--eth-colors-green-500);
  --eth-colors-error: var(--eth-colors-red-500);
  --eth-colors-attention: var(--eth-colors-yellow-200);
}

.chakra-ui-light :host:not([data-theme]),
.chakra-ui-light :root:not([data-theme]),
.chakra-ui-light [data-theme]:not([data-theme]),
[data-theme=light] :host:not([data-theme]),
[data-theme=light] :root:not([data-theme]),
[data-theme=light] [data-theme]:not([data-theme]),
:host[data-theme=light],
:root[data-theme=light],
[data-theme][data-theme=light] {
  --eth-colors-black: #000000;
  --eth-colors-white: #ffffff;
  --eth-colors-chakra-body-text: var(--eth-colors-gray-800);
  --eth-colors-chakra-body-bg: var(--eth-colors-white);
  --eth-colors-chakra-border-color: var(--eth-colors-gray-200);
  --eth-colors-chakra-placeholder-color: var(--eth-colors-gray-500);
  --eth-colors-white500: #ffffff;
  --eth-colors-white600: #f7f7f7;
  --eth-colors-white700: #e5e5e5;
  --eth-colors-white800: #ccc;
  --eth-colors-white900: #b2b2b2;
  --eth-colors-black500: #000000;
  --eth-colors-black400: #191919;
  --eth-colors-black300: #333;
  --eth-colors-black200: #4c4c4c;
  --eth-colors-black100: #666;
  --eth-colors-black50: #7f7f7f;
  --eth-colors-boxShadow: rgba(0, 0, 0, 0.12);
  --eth-colors-boxShadowHover: rgba(0, 0, 0, 0.24);
  --eth-colors-secondary: #b2b2b2;
  --eth-colors-success900: #031f13;
  --eth-colors-success800: #063f27;
  --eth-colors-success700: #095e3a;
  --eth-colors-success600: #0c7e4e;
  --eth-colors-success500: #109e62;
  --eth-colors-success400: #3fb181;
  --eth-colors-success300: #6fc4a0;
  --eth-colors-success200: #9fd8c0;
  --eth-colors-success100: #cfebdf;
  --eth-colors-fail900: #240000;
  --eth-colors-fail800: #490000;
  --eth-colors-fail700: #6e0000;
  --eth-colors-fail600: #930000;
  --eth-colors-fail: #b80000;
  --eth-colors-fail500: #b80000;
  --eth-colors-fail400: #c63333;
  --eth-colors-fail300: #d46666;
  --eth-colors-fail200: #e29999;
  --eth-colors-fail100: #f0cccc;
  --eth-colors-tagBlue: #d1d1ff;
  --eth-colors-tagOrange: #ffe3d3;
  --eth-colors-tagGreen: #cfebdf;
  --eth-colors-tagRed: #f0cccc;
  --eth-colors-tagTurquoise: #ccfcff;
  --eth-colors-tagGray: #e5e5e5;
  --eth-colors-tagYellow: #fff8df;
  --eth-colors-tagMint: #e1fefa;
  --eth-colors-tagPink: #ffe5f9;
  --eth-colors-warning: #ffe3d3;
  --eth-colors-warningLink: #994515;
  --eth-colors-warningLinkHover: #321607;
  --eth-colors-lowBug: #ffe3d3;
  --eth-colors-mediumBug: #ffab7b;
  --eth-colors-primaryLight300: #7676ff;
  --eth-colors-primaryDark300: #ffab7b;
  --eth-colors-yellow: #fff8df;
  --eth-colors-gridYellow: #ffe78e;
  --eth-colors-gridRed: #ef7d7d;
  --eth-colors-gridBlue: #a7d0f4;
  --eth-colors-gridGreen: #6fc4a0;
  --eth-colors-gridOrange: #ffab7b;
  --eth-colors-gridPink: #ffa1c3;
  --eth-colors-gridPurple: #a4a4ff;
  --eth-colors-infoBanner: #e8e8ff;
  --eth-colors-buttonColor: #ffffff;
  --eth-colors-upgradesGradient: linear-gradient(285.24deg, #F7CBC0 0%, #F4B1AB 29.8%, #8476D9 49.78%, #85ACF9 54.14%, #1C1CE1 61.77%, #000000 69.77%);
  --eth-colors-runNodeGradient: linear-gradient(0deg, rgba(153, 157, 244, 0.1) 0%, rgba(153, 157, 244, 0) 100%), linear-gradient(270.72deg, #FDF0FF 0.62%, rgba(236, 195, 195, 0.557292) 32.61%, rgba(207, 189, 230, 0.296875) 49.67%, rgba(196, 196, 196, 0) 72.88%);
  --eth-colors-runNodeGradient2: linear-gradient(135deg, rgba(79, 113, 235, 0.2) 9.8%, rgba(232, 79, 235, 0.2) 92.84%);
  --eth-colors-ghostCardBackground: #ffffff;
  --eth-colors-ghostCardGhost: #f7f7f7;
  --eth-colors-secondaryButtonBackgroundActive: #e5e5e5;
  --eth-colors-primary: var(--eth-colors-blue-500);
  --eth-colors-primary950: #040426;
  --eth-colors-primary900: #050532;
  --eth-colors-primary800: #0b0b66;
  --eth-colors-primary700: #101099;
  --eth-colors-primary600: #1616cc;
  --eth-colors-primary500: #1c1cff;
  --eth-colors-primary400: #4949ff;
  --eth-colors-primary300: #7676ff;
  --eth-colors-primary200: #a4a4ff;
  --eth-colors-primary100: #d1d1ff;
  --eth-colors-primaryHover: var(--eth-colors-blue-300);
  --eth-colors-primaryActive: #1616cc;
  --eth-colors-lightBorder: #ececec;
  --eth-colors-priceCardBorder: #ececec;
  --eth-colors-priceCardBorderNegative: #ececec;
  --eth-colors-searchBorder: #7f7f7f;
  --eth-colors-searchBackground: #ffffff;
  --eth-colors-searchBackgroundEmpty: #f2f2f2;
  --eth-colors-searchResultText: #33363d;
  --eth-colors-searchResultBackground: #f1f3f5;
  --eth-colors-selectHover: #d1d1ff;
  --eth-colors-selectActive: #a4a4ff;
  --eth-colors-dropdownBackground: #ffffff;
  --eth-colors-dropdownBackgroundHover: #f2f2f2;
  --eth-colors-dropdownBorder: #e5e5e5;
  --eth-colors-markBackground: rgba(143, 187, 237, .1);
  --eth-colors-markUnderline: rgba(143, 187, 237, .5);
  --eth-colors-modalBackground: hsla(0, 0%, 69.8%, 0.9);
  --eth-colors-text: #333333;
  --eth-colors-text200: #666666;
  --eth-colors-text300: #4c4c4c;
  --eth-colors-textTableOfContents: #7f7f7f;
  --eth-colors-background: var(--eth-colors-white);
  --eth-colors-ednBackground: #f7f7f7;
  --eth-colors-layer2ContentSecondary: #e5e5e5;
  --eth-colors-border: #e5e5e5;
  --eth-colors-tableBoxShadow: 0 14px 66px rgba(0, 0, 0, .07), 0 10px 17px rgba(0, 0, 0, .03), 0 4px 7px rgba(0, 0, 0, .05);
  --eth-colors-tableItemBoxShadow: rgba(0, 0, 0, 0.1);
  --eth-colors-tableBackgroundHover: #f2f2f2;
  --eth-colors-preBackground: #f2f2f2;
  --eth-colors-preBorder: rgba(0, 0, 0, .05);
  --eth-colors-homeDivider: #a4a4f3;
  --eth-colors-displayDark: none;
  --eth-colors-displayLight: block;
  --eth-colors-grayBackground: #fcfcfc;
  --eth-colors-cardGradient: radial-gradient(46.28% 66.31% at 66.95% 58.35%, #e6e6f7 0%, #e7edfa 50%, #e9fbfa 100%);
  --eth-colors-upgradeStatusBackground: linear-gradient(180deg, rgba(0, 240, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1c1ce1;
  --eth-colors-upgradeStatusShippedBackground: linear-gradient(180deg, rgba(0, 240, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #109e62;
  --eth-colors-upgradeStatusBorder: none;
  --eth-colors-upgradeStatusShippedBorder: none;
  --eth-colors-mainnet: #9fd8c0;
  --eth-colors-mainnetBorder: #7f7f7f;
  --eth-colors-beaconchain: #ccfcff;
  --eth-colors-beaconchainBorder: #7f7f7f;
  --eth-colors-shard: #ffe3d3;
  --eth-colors-shardBorder: #7f7f7f;
  --eth-colors-infoLink: #0b0b66;
  --eth-colors-infoLinkHover: #050532;
  --eth-colors-cardBoxShadow: 4px 4px 0px 0px #d2d2f9;
  --eth-colors-homeBoxMint: #e1fefa;
  --eth-colors-homeBoxTurquoise: #ccfcff;
  --eth-colors-homeBoxOrange: #ffe3d3;
  --eth-colors-homeBoxPurple: #e8e8ff;
  --eth-colors-homeBoxPink: #ffe5f9;
  --eth-colors-codeBackground: #fafafa;
  --eth-colors-rollupDevDocList: #e8e8ff;
  --eth-colors-beta: radial-gradient(25.56% 133.51% at 28.36% 45.54%, rgba(28, 28, 225, 0) 0%, rgba(28, 28, 225, 0.06) 100%);
  --eth-colors-offBackground: #f7f7f7;
  --eth-colors-stakingPillPlatform: #cd9df3;
  --eth-colors-stakingPillUI: #ebd27a;
  --eth-colors-stakingGold: #be8d10;
  --eth-colors-stakingGoldFill: #fef9ef;
  --eth-colors-stakingGreen: #129e5b;
  --eth-colors-stakingGreenFill: #f7faf1;
  --eth-colors-stakingBlue: #0b83dc;
  --eth-colors-stakingBlueFill: #f1fcf5;
  --eth-colors-stakingRed: #a0524c;
  --eth-colors-stakingRedFill: #f8fbf9;
  --eth-colors-layer2Gradient: linear-gradient(85.12deg, rgba(185, 185, 241, 0.2) 0%, rgba(84, 132, 234, 0.2) 56.29%, rgba(58, 142, 137, 0.2) 99.99%);
  --eth-colors-feedbackGradient: linear-gradient(102.7deg, rgba(185, 185, 241, 0.2) 0%, rgba(84, 132, 234, 0.2) 51.56%, rgba(58, 142, 137, 0.2) 100%);
  --eth-colors-bannerGridGradient: linear-gradient(90deg, rgba(127, 127, 213, 0.2) 0%, rgba(132, 145, 221, 0.2) 50%, rgba(145, 234, 228, 0.2) 100%);
  --eth-colors-primaryLight: var(--eth-colors-blue-100);
  --eth-colors-primaryDark: var(--eth-colors-blue-700);
  --eth-colors-body: var(--eth-colors-gray-700);
  --eth-colors-bodyLight: var(--eth-colors-gray-500);
  --eth-colors-disabled: var(--eth-colors-gray-400);
}

.chakra-ui-dark :host:not([data-theme]),
.chakra-ui-dark :root:not([data-theme]),
.chakra-ui-dark [data-theme]:not([data-theme]),
[data-theme=dark] :host:not([data-theme]),
[data-theme=dark] :root:not([data-theme]),
[data-theme=dark] [data-theme]:not([data-theme]),
:host[data-theme=dark],
:root[data-theme=dark],
[data-theme][data-theme=dark] {
  --eth-colors-black: #000000;
  --eth-colors-white: #ffffff;
  --eth-colors-chakra-body-text: var(--eth-colors-whiteAlpha-900);
  --eth-colors-chakra-body-bg: var(--eth-colors-gray-800);
  --eth-colors-chakra-border-color: var(--eth-colors-whiteAlpha-300);
  --eth-colors-chakra-placeholder-color: var(--eth-colors-whiteAlpha-400);
  --eth-colors-white500: #ffffff;
  --eth-colors-white600: #f7f7f7;
  --eth-colors-white700: #e5e5e5;
  --eth-colors-white800: #ccc;
  --eth-colors-white900: #b2b2b2;
  --eth-colors-black500: #000000;
  --eth-colors-black400: #191919;
  --eth-colors-black300: #333;
  --eth-colors-black200: #4c4c4c;
  --eth-colors-black100: #666;
  --eth-colors-black50: #7f7f7f;
  --eth-colors-boxShadow: rgba(0, 0, 0, 0.12);
  --eth-colors-boxShadowHover: rgba(0, 0, 0, 0.24);
  --eth-colors-secondary: #b2b2b2;
  --eth-colors-success900: #031f13;
  --eth-colors-success800: #063f27;
  --eth-colors-success700: #095e3a;
  --eth-colors-success600: #0c7e4e;
  --eth-colors-success500: #109e62;
  --eth-colors-success400: #3fb181;
  --eth-colors-success300: #6fc4a0;
  --eth-colors-success200: #9fd8c0;
  --eth-colors-success100: #cfebdf;
  --eth-colors-fail900: #240000;
  --eth-colors-fail800: #490000;
  --eth-colors-fail700: #6e0000;
  --eth-colors-fail600: #930000;
  --eth-colors-fail: #b80000;
  --eth-colors-fail500: #b80000;
  --eth-colors-fail400: #c63333;
  --eth-colors-fail300: #d46666;
  --eth-colors-fail200: #e29999;
  --eth-colors-fail100: #f0cccc;
  --eth-colors-tagBlue: #d1d1ff;
  --eth-colors-tagOrange: #ffe3d3;
  --eth-colors-tagGreen: #cfebdf;
  --eth-colors-tagRed: #f0cccc;
  --eth-colors-tagTurquoise: #ccfcff;
  --eth-colors-tagGray: #e5e5e5;
  --eth-colors-tagYellow: #fff8df;
  --eth-colors-tagMint: #e1fefa;
  --eth-colors-tagPink: #ffe5f9;
  --eth-colors-warning: #ffe3d3;
  --eth-colors-warningLink: #994515;
  --eth-colors-warningLinkHover: #321607;
  --eth-colors-lowBug: #ffe3d3;
  --eth-colors-mediumBug: #ffab7b;
  --eth-colors-primaryLight300: #7676ff;
  --eth-colors-primaryDark300: #ffab7b;
  --eth-colors-yellow: #fff8df;
  --eth-colors-gridYellow: #ffe78e;
  --eth-colors-gridRed: #ef7d7d;
  --eth-colors-gridBlue: #a7d0f4;
  --eth-colors-gridGreen: #6fc4a0;
  --eth-colors-gridOrange: #ffab7b;
  --eth-colors-gridPink: #ffa1c3;
  --eth-colors-gridPurple: #a4a4ff;
  --eth-colors-infoBanner: #e8e8ff;
  --eth-colors-buttonColor: #333;
  --eth-colors-upgradesGradient: linear-gradient(285.24deg, #f7cbc0 0%, #fbeae3 17.81%, #f4b1ab 29.8%, #8476d9 49.78%, #8db4ff 69.77%);
  --eth-colors-runNodeGradient: linear-gradient(0deg, rgba(153, 157, 244, 0.35) 0%, rgba(153, 157, 244, 0) 100%), linear-gradient(89.24deg, rgba(253, 240, 255, 0.7) -64.3%, rgba(212, 119, 119, 0.390104) -7.43%, rgba(188, 163, 220, 0.207813) 46.66%, rgba(196, 196, 196, 0) 99.16%);
  --eth-colors-runNodeGradient2: linear-gradient(135deg, rgba(79, 113, 235, 0.2) 9.8%, rgba(232, 79, 235, 0.2) 92.84%);
  --eth-colors-ghostCardBackground: #333;
  --eth-colors-ghostCardGhost: #7f7f7f;
  --eth-colors-secondaryButtonBackgroundActive: #333;
  --eth-colors-primary: var(--eth-colors-orange-500);
  --eth-colors-primary950: #190b03;
  --eth-colors-primary900: #321607;
  --eth-colors-primary800: #662e0e;
  --eth-colors-primary700: #994515;
  --eth-colors-primary600: #cc5c1c;
  --eth-colors-primary500: #ff7324;
  --eth-colors-primary400: #ff8f4f;
  --eth-colors-primary300: #ffab7b;
  --eth-colors-primary200: #ffc7a7;
  --eth-colors-primary100: #ffe3d3;
  --eth-colors-primaryHover: var(--eth-colors-orange-300);
  --eth-colors-primaryActive: #ffc7a7;
  --eth-colors-lightBorder: #404040;
  --eth-colors-priceCardBorder: #095e3a;
  --eth-colors-priceCardBorderNegative: #d46666;
  --eth-colors-searchBorder: #b2b2b2;
  --eth-colors-searchBackground: #4c4c4c;
  --eth-colors-searchBackgroundEmpty: #333333;
  --eth-colors-searchResultText: #f1f3f5;
  --eth-colors-searchResultBackground: #33363d;
  --eth-colors-selectHover: #994515;
  --eth-colors-selectActive: #cc5c1c;
  --eth-colors-dropdownBackground: #191919;
  --eth-colors-dropdownBackgroundHover: #000000;
  --eth-colors-dropdownBorder: #333333;
  --eth-colors-markBackground: rgb(255, 115, 36, .1);
  --eth-colors-markUnderline: rgb(255, 115, 36, .5);
  --eth-colors-modalBackground: rgba(25, 25, 25, 0.8);
  --eth-colors-text: #f2f2f2;
  --eth-colors-text200: #b2b2b2;
  --eth-colors-text300: #cccccc;
  --eth-colors-textTableOfContents: hsla(0, 0%, 69.8%, .8);
  --eth-colors-background: var(--eth-colors-gray-700);
  --eth-colors-ednBackground: #191919;
  --eth-colors-layer2ContentSecondary: #333;
  --eth-colors-border: #333;
  --eth-colors-tableBoxShadow: 0 14px 66px hsla(0, 0%, 96.1%, .07), 0 10px 17px hsla(0, 0%, 96.1%, .03), 0 4px 7px hsla(0, 0%, 96.1%, .05);
  --eth-colors-tableItemBoxShadow: hsla(0, 0%, 100%, .1);
  --eth-colors-tableBackgroundHover: rgba(255, 115, 36, .013);
  --eth-colors-preBackground: #191919;
  --eth-colors-preBorder: hsla(0, 0%, 100%, .05);
  --eth-colors-homeDivider: #ffc7a7;
  --eth-colors-displayDark: block;
  --eth-colors-displayLight: none;
  --eth-colors-grayBackground: #272627;
  --eth-colors-cardGradient: linear-gradient(49.21deg, rgba(127, 127, 213, 0.2) 19.87%, rgba(134, 168, 231, 0.2) 58.46%, rgba(145, 234, 228, 0.2) 97.05%);
  --eth-colors-upgradeStatusBackground: #222222;
  --eth-colors-upgradeStatusShippedBackground: #222222;
  --eth-colors-upgradeStatusBorder: #a4a4ff solid 2px;
  --eth-colors-upgradeStatusShippedBorder: #3fb181 solid 2px;
  --eth-colors-mainnet: #222222;
  --eth-colors-mainnetBorder: #6fc4a0;
  --eth-colors-beaconchain: #222222;
  --eth-colors-beaconchainBorder: #ffe5f9;
  --eth-colors-shard: #222222;
  --eth-colors-shardBorder: #ff7324;
  --eth-colors-infoLink: #0b0b66;
  --eth-colors-infoLinkHover: #050532;
  --eth-colors-cardBoxShadow: 4px 4px 0px 0px #ffab7c;
  --eth-colors-homeBoxMint: #2d3332;
  --eth-colors-homeBoxTurquoise: #293233;
  --eth-colors-homeBoxOrange: #332821;
  --eth-colors-homeBoxPurple: #212131;
  --eth-colors-homeBoxPink: #332027;
  --eth-colors-codeBackground: #2a2734;
  --eth-colors-rollupDevDocList: #4c4c4c;
  --eth-colors-offBackground: #181818;
  --eth-colors-stakingPillPlatform: #cd9df3;
  --eth-colors-stakingPillUI: #ebd27a;
  --eth-colors-stakingGold: #F2BB2F;
  --eth-colors-stakingGoldFill: #373228;
  --eth-colors-stakingGreen: #49DE96;
  --eth-colors-stakingGreenFill: #30342b;
  --eth-colors-stakingBlue: #A9D3F2;
  --eth-colors-stakingBlueFill: #2b352f;
  --eth-colors-stakingRed: #D6BBB9;
  --eth-colors-stakingRedFill: #313432;
  --eth-colors-layer2Gradient: linear-gradient(83.46deg, rgba(127, 127, 213, 0.2) 7.03%, rgba(138, 168, 231, 0.2) 52.42%, rgba(145, 234, 228, 0.2) 98.77%), #1E1E1E;
  --eth-colors-feedbackGradient: linear-gradient(83.46deg, #2C2C32 7.03%, #44404D 52.42%, #303038 98.77%);
  --eth-colors-bannerGridGradient: linear-gradient(90deg, rgba(172, 182, 229, 0.08) 0%, rgba(134, 253, 232, 0.08) 100%);
  --eth-colors-primaryLight: var(--eth-colors-orange-100);
  --eth-colors-primaryDark: var(--eth-colors-orange-800);
  --eth-colors-body: var(--eth-colors-gray-100);
  --eth-colors-bodyLight: var(--eth-colors-gray-100);
  --eth-colors-disabled: var(--eth-colors-gray-500);
}


html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

body {
  position: relative;
  min-height: 100%;
  font-feature-settings: 'kern';
}

*,
*::before,
*::after {
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
}

main {
  display: block;
}

hr {
  border-top-width: 1px;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none !important;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: -webkit-box;
  display: -webkit-list-item;
  display: -ms-list-itembox;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

body,
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  background: transparent;
  padding: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

button,
[role="button"] {
  cursor: pointer;
}

button::-moz-focus-inner {
  border: 0 !important;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

img,
video {
  max-width: 100%;
  height: auto;
}

[data-js-focus-visible] :focus:not([data-focus-visible-added]):not([data-focus-visible-disabled]) {
  outline: none;
  box-shadow: none;
}

select::-ms-expand {
  display: none;
}


body {
  font-family: var(--eth-fonts-body);
  color: var(--eth-colors-chakra-body-text);
  background: var(--eth-colors-white);
  transition-property: background-color;
  transition-duration: var(--eth-transition-duration-normal);
  line-height: 1.6rem;
}

*::-webkit-input-placeholder {
  color: var(--eth-colors-chakra-placeholder-color);
}

*::-moz-placeholder {
  color: var(--eth-colors-chakra-placeholder-color);
}

*:-ms-input-placeholder {
  color: var(--eth-colors-chakra-placeholder-color);
}

*::placeholder {
  color: var(--eth-colors-chakra-placeholder-color);
}

*,
*::before,
::after {
  border-color: var(--eth-colors-chakra-border-color);
  word-wrap: break-word;
}

p {
  margin: 0px 0px 1.45rem;
}

ul,
ol {
  margin: 0px 0px 1.45rem 1.45rem;
  padding: 0px;
}

iframe {
  display: block;
  max-width: 560px;
  margin: 32px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 2rem 0;
  line-height: 1.4;
  scroll-margin-top: navHeight;
  scroll-snap-margin: navHeight;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: 2rem;
  margin-top: 3rem;
  font-weight: 600;
}

@media (max-width: 768px) {
  h2 {
    font-size: 1.5rem;
  }
}

h3 {
  font-size: 1.5rem;
  margin-top: 2.5rem;
  font-weight: 600;
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.25rem;
  }
}

h4 {
  font-size: 1.25rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  h4 {
    font-size: 1rem;
  }
}

h5 {
  font-size: 1rem;
  font-weight: 450;
}

h6 {
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: uppercase;
}

.header-anchor {
  position: relative !important;
  display: initial;
  margin-left: -1.5em;
  padding-right: 0.5rem !important;
  font-size: 1rem;
  vertical-align: middle;
}

.header-anchor svg {
  display: inline;
  fill: var(--eth-colors-primary);
  visibility: hidden;
}

h1:hover .header-anchor svg,
h2:hover .header-anchor svg,
h3:hover .header-anchor svg,
h4:hover .header-anchor svg,
h5:hover .header-anchor svg,
h6:hover .header-anchor svg {
  visibility: visible;
}

.header-anchor:focus svg {
  visibility: visible;
}

.css-nwm97r {
  background-color: #1c1cff;
}

.css-1afxvkq {
  line-height: 2rem;
  position: absolute;
  top: -3rem;
  margin-left: 0.5rem;
  color: #ffffff;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-1afxvkq:focus {
  position: static;
}


.css-1l2hayi {
  display: none;
  bottom: 2rem;
  right: 2rem;
  position: fixed;
  z-index: 99;
}

@media (max-width: 768px) {
  .css-1l2hayi {
    bottom: 0rem;
    right: 0rem;
  }
}


.css-12jhb8b {
  padding: 1rem;
  max-height: 100%;
  max-width: 600px;
  background: #e8e8ff;
  color: #333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px 0px;
  border-radius: 2px;
}

@media (max-width: 768px) {
  .css-12jhb8b {
    max-width: 100%;
    box-shadow: 0px -4px 10px 0px #333333 10%;
  }
}


.css-1meiskl {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  margin: 1rem;
}

@media (max-width: 414px) {
  .css-1meiskl {
    margin-top: 2.5rem;
  }
}


.css-xshbwr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1rem;
}

@media (max-width: 414px) {
  .css-xshbwr {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
}


.css-1x9hf7k {
  font-weight: 700;
  line-height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}


.css-13rb6ut {
  padding-top: 0.5rem;
}

@media (max-width: 414px) {
  .css-13rb6ut {
    margin-bottom: 1rem;
  }
}


.css-1cjq9jc {
  display: inline-block;
  margin-left: 0.5rem;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding-top: 0.5rem;
}

.css-1cjq9jc>img {
  width: 1.5em !important;
  height: 1.5em !important;
  margin: 0 !important;
}

@media (max-width: 414px) {
  .css-1cjq9jc {
    margin-bottom: 1rem;
  }
}


.css-bsq7kv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 414px) {
  .css-bsq7kv {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
}


.css-af9qpk {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  background: var(--eth-colors-primary);
  color: var(--eth-colors-buttonColor);
  border: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-af9qpk:focus-visible,
.css-af9qpk[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-af9qpk[disabled],
.css-af9qpk[aria-disabled=true],
.css-af9qpk[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  background: var(--eth-colors-disabled);
}

.css-af9qpk:hover,
.css-af9qpk[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-primary);
  opacity: 0.8;
}

.css-af9qpk:hover[disabled],
.css-af9qpk[data-hover][disabled],
.css-af9qpk:hover[aria-disabled=true],
.css-af9qpk[data-hover][aria-disabled=true],
.css-af9qpk:hover[data-disabled],
.css-af9qpk[data-hover][data-disabled] {
  background: var(--eth-colors-gray-100);
}

.css-af9qpk:focus,
.css-af9qpk[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.css-af9qpk:active,
.css-af9qpk[data-active] {
  background: var(--eth-colors-primaryHover);
}


.css-dx5290 {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  background: var(--eth-colors-primary);
  color: var(--eth-colors-buttonColor);
  border: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-dx5290:hover,
.css-dx5290[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-dx5290:focus-visible,
.css-dx5290[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-dx5290:focus,
.css-dx5290[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-dx5290:focus-visible,
.css-dx5290[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-dx5290[disabled],
.css-dx5290[aria-disabled=true],
.css-dx5290[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  background: var(--eth-colors-disabled);
}

.css-dx5290:hover,
.css-dx5290[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-primary);
  opacity: 0.8;
}

.css-dx5290:hover[disabled],
.css-dx5290[data-hover][disabled],
.css-dx5290:hover[aria-disabled=true],
.css-dx5290[data-hover][aria-disabled=true],
.css-dx5290:hover[data-disabled],
.css-dx5290[data-hover][data-disabled] {
  background: var(--eth-colors-gray-100);
}

.css-dx5290:focus,
.css-dx5290[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.css-dx5290:active,
.css-dx5290[data-active] {
  background: var(--eth-colors-primaryHover);
}


.css-yae84f {
  margin-left: 0.5rem;
  color: #333333;
  border: 1px solid #333333;
  background-color: transparent;
}

@media (max-width: 414px) {
  .css-yae84f {
    margin-left: 0rem;
    margin-top: 0.5rem;
  }
}


.css-162z3h6 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  border: var(--eth-borders-1px);
  border-color: var(--eth-colors-text);
  color: var(--eth-colors-text);
  background: var(--eth-colors-transparent);
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-left: 0.5rem;
  color: #333333;
  border: 1px solid #333333;
  background-color: transparent;
}

.css-162z3h6:focus-visible,
.css-162z3h6[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-162z3h6[disabled],
.css-162z3h6[aria-disabled=true],
.css-162z3h6[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  color: var(--eth-colors-disabled);
  border-color: var(--eth-colors-disabled);
}

.css-162z3h6:hover,
.css-162z3h6[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-background);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

.css-162z3h6:hover[disabled],
.css-162z3h6[data-hover][disabled],
.css-162z3h6:hover[aria-disabled=true],
.css-162z3h6[data-hover][aria-disabled=true],
.css-162z3h6:hover[data-disabled],
.css-162z3h6[data-hover][data-disabled] {
  background: initial;
}

.css-162z3h6:focus,
.css-162z3h6[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-background);
}

.chakra-button__group[data-attached]>.css-162z3h6:not(:last-of-type) {
  -webkit-margin-end: -1px;
  margin-inline-end: -1px;
}

.css-162z3h6:active,
.css-162z3h6[data-active] {
  background: var(--eth-colors-primaryLight);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

@media (max-width: 414px) {
  .css-162z3h6 {
    margin-left: 0rem;
    margin-top: 0.5rem;
  }
}


.css-vypuer {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  border: var(--eth-borders-1px);
  border-color: var(--eth-colors-text);
  color: var(--eth-colors-text);
  background: var(--eth-colors-transparent);
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-left: 0.5rem;
  color: #333333;
  border: 1px solid #333333;
  background-color: transparent;
}

.css-vypuer:hover,
.css-vypuer[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-vypuer:focus-visible,
.css-vypuer[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-vypuer:focus,
.css-vypuer[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-vypuer:focus-visible,
.css-vypuer[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-vypuer[disabled],
.css-vypuer[aria-disabled=true],
.css-vypuer[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  color: var(--eth-colors-disabled);
  border-color: var(--eth-colors-disabled);
}

.css-vypuer:hover,
.css-vypuer[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-background);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

.css-vypuer:hover[disabled],
.css-vypuer[data-hover][disabled],
.css-vypuer:hover[aria-disabled=true],
.css-vypuer[data-hover][aria-disabled=true],
.css-vypuer:hover[data-disabled],
.css-vypuer[data-hover][data-disabled] {
  background: initial;
}

.css-vypuer:focus,
.css-vypuer[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-background);
}

.chakra-button__group[data-attached]>.css-vypuer:not(:last-of-type) {
  -webkit-margin-end: -1px;
  margin-inline-end: -1px;
}

.css-vypuer:active,
.css-vypuer[data-active] {
  background: var(--eth-colors-primaryLight);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

@media (max-width: 414px) {
  .css-vypuer {
    margin-left: 0rem;
    margin-top: 0.5rem;
  }
}


.css-1q9a7w9 {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
}


.css-1rvzqx1 {
  fill: #b2b2b2;
  cursor: pointer;
}

.css-1rvzqx1:hover svg {
  fill: #1c1cff;
}


.css-1l2hayi {
  display: none;
  bottom: 2rem;
  right: 2rem;
  position: fixed;
  z-index: 99;
}

@media (max-width: 768px) {
  .css-1l2hayi {
    bottom: 0rem;
    right: 0rem;
  }
}


.css-12jhb8b {
  padding: 1rem;
  max-height: 100%;
  max-width: 600px;
  background: #e8e8ff;
  color: #333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px 0px;
  border-radius: 2px;
}

@media (max-width: 768px) {
  .css-12jhb8b {
    max-width: 100%;
    box-shadow: 0px -4px 10px 0px #333333 10%;
  }
}


.css-1meiskl {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  margin: 1rem;
}

@media (max-width: 414px) {
  .css-1meiskl {
    margin-top: 2.5rem;
  }
}


.css-xshbwr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1rem;
}

@media (max-width: 414px) {
  .css-xshbwr {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
}


.css-1x9hf7k {
  font-weight: 700;
  line-height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}


.css-13rb6ut {
  padding-top: 0.5rem;
}

@media (max-width: 414px) {
  .css-13rb6ut {
    margin-bottom: 1rem;
  }
}


.css-1cjq9jc {
  display: inline-block;
  margin-left: 0.5rem;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding-top: 0.5rem;
}

.css-1cjq9jc>img {
  width: 1.5em !important;
  height: 1.5em !important;
  margin: 0 !important;
}

@media (max-width: 414px) {
  .css-1cjq9jc {
    margin-bottom: 1rem;
  }
}


.css-bsq7kv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 414px) {
  .css-bsq7kv {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
}


.css-wkp7oy {
  -webkit-text-decoration: none;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25em;
  text-align: center;
  cursor: pointer;
  background-color: #1c1cff;
  color: #ffffff;
  border: 1px solid transparent;
}

.css-wkp7oy:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.css-wkp7oy:hover {
  background-color: rgba(28, 28, 225, 0.8);
  box-shadow: 4px 4px 0px 0px #d2d2f9;
}

.css-wkp7oy:active {
  background-color: #1616cc;
}


.css-1q9a7w9 {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
}


.css-1rvzqx1 {
  fill: #b2b2b2;
  cursor: pointer;
}

.css-1rvzqx1:hover svg {
  fill: #1c1cff;
}


.css-13tash9 {
  position: relative;
  margin: 0px auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-flow: column;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
}

@media (min-width: 1024px) {
  .css-13tash9 {
    max-width: 1504px;
  }
}


.css-1rzl5xx {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 98;
  width: 100%;
}


.css-1lfigb {
  height: 4.75rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


.css-q5uto4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
}

@media (max-width: 1024px) {
  .css-q5uto4 {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}


.css-yd5a75 {
  -webkit-text-decoration: none;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


.css-h8lqpv {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-h8lqpv:hover,
.css-h8lqpv[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-h8lqpv:focus-visible,
.css-h8lqpv[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-h8lqpv:focus,
.css-h8lqpv[data-focus] {
  box-shadow: var(--eth-shadows-none);
}


.css-1exo2l1 {
  width: 22px;
  height: 35px;
  opacity: 0.85;
}

.css-1exo2l1:hover {
  opacity: 1;
}


.css-1gdkg6e {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 1024px) {
  .css-1gdkg6e {
    display: none;
  }
}


.css-jniy3o {
  margin: 0;
  margin-left: 2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style-type: none;
  list-style-image: none;
}


.css-s1w03o {
  white-space: nowrap;
  margin: 0;
  color: #333333;
}

.css-s1w03o:hover {
  color: #1c1cff;
}


.css-14sdthb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1.5rem;
}

.css-14sdthb:hover>svg {
  fill: #1c1cff;
}


.css-2nxmhn {
  fill: #b2b2b2;
}

.css-2nxmhn:hover svg {
  fill: #1c1cff;
}


.css-10vwoqs {
  margin: 0;
  position: absolute;
  margin-top: -1rem;
  list-style-type: none;
  list-style-image: none;
  top: 100%;
  width: auto;
  border-radius: 0.5em;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  padding: 1rem 0;
}


.css-h9rgb6 {
  margin: 0;
  color: #333333;
}

.css-h9rgb6:hover {
  color: #1c1cff;
  background: #f2f2f2;
}


.css-1wmssx1 {
  -webkit-text-decoration: none;
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  color: #666666;
}

.css-1wmssx1 svg {
  fill: #666666;
}

.css-1wmssx1:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-1wmssx1:hover svg {
  fill: #1c1cff;
}


.css-1vyy6tq {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  color: #666666;
}

.css-1vyy6tq:hover,
.css-1vyy6tq[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-1vyy6tq:focus-visible,
.css-1vyy6tq[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-1vyy6tq:focus,
.css-1vyy6tq[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-1vyy6tq svg {
  fill: #666666;
}

.css-1vyy6tq:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-1vyy6tq:hover svg {
  fill: #1c1cff;
}


.css-1xhj18k {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}


.css-1nxebte {
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  color: #333333;
}

.css-1nxebte:first-child {
  margin-top: 0;
}


.css-svk96r {
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


.css-1s445dq {
  position: relative;
  display: grid;
  grid-gap: 1em;
}


.css-1m90wsh {
  margin: 0;
  position: relative;
  border-radius: 0.25em;
}


.css-1w9oosq {
  border: 1px solid #7f7f7f;
  color: #333333;
  background: #ffffff;
  padding: 0.5rem;
  padding-right: 2rem;
  border-radius: 0.25em;
  width: 100%;
}

.css-1w9oosq:focus {
  outline: #1c1cff auto 1px;
}

@media only screen and (min-width: 1024px) {
  .css-1w9oosq {
    padding-left: 2rem;
  }
}


.css-209ue6 {
  border: 1px solid #7f7f7f;
  border-radius: 0.25em;
  color: #333333;
  display: none;
  margin-bottom: 0;
  padding: 0 6px;
  position: absolute;
  right: 6px;
  top: 20%;
}

@media only screen and (min-width: 1024px) {
  .css-209ue6 {
    display: inline-block;
  }
}


.css-vw4xmf {
  fill: #b2b2b2;
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -12px;
}

.css-vw4xmf:hover svg {
  fill: #1c1cff;
}

@media only screen and (min-width: 1024px) {
  .css-vw4xmf {
    left: 6px;
  }
}


.css-1vwvvvd {
  display: none;
  max-height: 80vh;
  overflow: scroll;
  z-index: 2;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5em);
  width: 80vw;
  max-width: 30em;
  box-shadow: 0 0 5px 0;
  padding: 0.5rem;
  background: #ffffff;
  border-radius: 0.25em;
}

@media (max-width: 1024px) {
  .css-1vwvvvd {
    width: 100%;
  }
}

.css-1vwvvvd>*+* {
  padding-top: 1em !important;
  border-top: 2px solid black;
}

.css-1vwvvvd li {
  margin-bottom: 0.4rem;
}

.css-1vwvvvd li+li {
  padding-top: 0.7em;
  border-top: 1px solid #ececec;
}

.css-1vwvvvd ul {
  margin: 0;
  list-style: none;
}

.css-1vwvvvd mark {
  color: #1c1cff;
  box-shadow: inset 0 -2px 0 0 rgba(143, 187, 237, .5);
}

.css-1vwvvvd header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 0.3em;
}

.css-1vwvvvd header h3 {
  color: #ffffff;
  background: #4c4c4c;
  padding: 0.1em 0.4em;
  border-radius: 0.25em;
}

.css-1vwvvvd h3 {
  margin: 0 0 0.5em;
}

.css-1vwvvvd h4 {
  margin-bottom: 0.3em;
}

.css-1vwvvvd a {
  -webkit-text-decoration: none;
  text-decoration: none;
}


.css-sxshx2 {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  display: inline-block;
  font: inherit;
  padding: initial;
  cursor: pointer;
  margin-left: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-sxshx2:hover svg {
  fill: #1c1cff;
}


.css-38lpna {
  fill: #b2b2b2;
  fill: #333333;
}

.css-38lpna:hover svg {
  fill: #1c1cff;
}


.css-15h6frg {
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-right: 2rem;
  color: #333333;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0;
  margin-left: 1rem;
}

.css-15h6frg svg {
  fill: #666666;
}

.css-15h6frg:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-15h6frg:hover svg {
  fill: #1c1cff;
}

.css-15h6frg:hover svg {
  fill: #1c1cff;
}


.css-ijht3y {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-right: 2rem;
  color: #333333;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0;
  margin-left: 1rem;
}

.css-ijht3y:hover,
.css-ijht3y[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-ijht3y:focus-visible,
.css-ijht3y[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-ijht3y:focus,
.css-ijht3y[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-ijht3y svg {
  fill: #666666;
}

.css-ijht3y:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-ijht3y:hover svg {
  fill: #1c1cff;
}

.css-ijht3y:hover svg {
  fill: #1c1cff;
}


.css-18tt7mj {
  padding-left: 0.5rem;
}


.css-hjrzhr {
  display: none;
}

@media (max-width: 1024px) {
  .css-hjrzhr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}


.css-1ty3j1g {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  display: inline-block;
  font: inherit;
  padding: initial;
  cursor: pointer;
  margin-left: 1rem;
}


.css-ojo301 {
  fill: #b2b2b2;
  fill: #333333;
  margin-right: 1rem;
}

.css-ojo301:hover svg {
  fill: #1c1cff;
}


.css-1ew3q6e {
  margin: 0 0.125rem;
  width: 1.5rem;
  height: 2.5rem;
  position: relative;
  stroke-width: 2px;
  z-index: 100;
}

.css-1ew3q6e>path {
  stroke: #333333;
  fill: none;
}

.css-1ew3q6e:hover {
  color: #1c1cff;
}

.css-1ew3q6e:hover>path {
  stroke: #1c1cff;
}


.css-h8etzh {
  position: fixed;
  background: hsla(0, 0%, 69.8%, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
}


.css-zhjif1 {
  background: #ffffff;
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  max-width: 450px;
}


.css-oi7ycx {
  margin: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 3rem 1rem 8rem;
}


.css-t585d3 {
  margin: 0;
  margin-bottom: 3rem;
  list-style-type: none;
  list-style-image: none;
}


.css-1nrcqrc {
  margin: 1rem 0;
  font-size: 1.3rem;
  color: #333333;
}


.css-ti75j2 {
  margin: 0;
}


.css-1yn3rwb {
  margin-bottom: 1rem;
  list-style-type: none;
  list-style-image: none;
  opacity: 0.7;
}

.css-1yn3rwb:hover {
  opacity: 1;
}


.css-1k3j10i {
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-right: 2rem;
  color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.css-1k3j10i svg {
  fill: #666666;
}

.css-1k3j10i:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-1k3j10i:hover svg {
  fill: #1c1cff;
}


.css-91i3fv {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-right: 2rem;
  color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.css-91i3fv:hover,
.css-91i3fv[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-91i3fv:focus-visible,
.css-91i3fv[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-91i3fv:focus,
.css-91i3fv[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-91i3fv svg {
  fill: #666666;
}

.css-91i3fv:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-91i3fv:hover svg {
  fill: #1c1cff;
}


.css-1p65to4 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  line-height: 1;
  color: #333333;
}


.css-6f7gax {
  background: #ffffff;
  border-top: 1px solid #ececec;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 108px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  z-index: 99;
}


.css-qnpcbq {
  -webkit-flex: 1 1 120px;
  -ms-flex: 1 1 120px;
  flex: 1 1 120px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: #333333;
}

.css-qnpcbq>svg {
  fill: #333333;
}

.css-qnpcbq:hover {
  color: #1c1cff;
}

.css-qnpcbq:hover>svg {
  fill: #1c1cff;
}


.css-cia9v4 {
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 400;
  letter-spacing: 0.04em;
  margin-top: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  opacity: 0.7;
}

.css-cia9v4:hover {
  opacity: 1;
}


.css-1vu6eds {
  -webkit-text-decoration: none;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333333;
}

.css-1vu6eds>svg {
  fill: #333333;
}

.css-1vu6eds:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-1vu6eds:hover>svg {
  fill: #1c1cff;
}


.css-1naw0gy {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333333;
}

.css-1naw0gy:hover,
.css-1naw0gy[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-1naw0gy:focus-visible,
.css-1naw0gy[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-1naw0gy:focus,
.css-1naw0gy[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-1naw0gy>svg {
  fill: #333333;
}

.css-1naw0gy:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-1naw0gy:hover>svg {
  fill: #1c1cff;
}


.css-1u0u6fz {
  background: #ffffff;
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  z-index: 101;
  padding: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}


.css-atmozw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-atmozw>svg {
  fill: #333333;
}


.css-1itof9b {
  z-index: 102;
  cursor: pointer;
}

.css-1itof9b>svg {
  fill: #333333;
}


.css-17s5pr0 {
  fill: #b2b2b2;
}

.css-17s5pr0:hover svg {
  fill: #1c1cff;
}


.css-kpi1f2 {
  color: #333333;
  background: #f2f2f2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 10vw;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  width: 280px;
  width: min(60vw, 280px);
  height: 280px;
  height: min(60vw, 280px);
  border-radius: 100%;
}


.css-14jmmgu {
  display: inline-block;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.css-14jmmgu>img {
  width: 3em !important;
  height: 3em !important;
  margin: 0 !important;
}


.css-13e62ei {
  height: 80px;
  margin-top: -80px;
}


.css-18nbm6b {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 1024px) {
  .css-18nbm6b {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}


.css-b6wrti {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}


.css-1q4m7qt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-around;
  -ms-flex-pack: space-around;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  overflow: visible;
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}


.css-16vi3jv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}


.css-18hx3xd {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}

.css-18hx3xd p {
  max-width: 100ch;
  margin: 0;
  padding: 0;
}

.css-18hx3xd a {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}


.css-1b3lhz1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  width: 100%;
  padding-top: var(--eth-space-4);
  padding-bottom: var(--eth-space-4);
  -webkit-padding-start: var(--eth-space-8);
  padding-inline-start: var(--eth-space-8);
  -webkit-padding-end: var(--eth-space-8);
  padding-inline-end: var(--eth-space-8);
  background: var(--eth-colors-primary);
  color: var(--eth-colors-background);
  border-bottom: 1px solid primary;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}

.css-1b3lhz1 a {
  color: var(--eth-colors-background);
}

.css-1b3lhz1 p {
  max-width: 100ch;
  margin: 0;
  padding: 0;
}

.css-1b3lhz1 a {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}


.css-1x1y8s5 {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
}

.css-1x1y8s5:hover,
.css-1x1y8s5[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-1x1y8s5:focus-visible,
.css-1x1y8s5[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-1x1y8s5:focus,
.css-1x1y8s5[data-focus] {
  box-shadow: var(--eth-shadows-none);
}


.css-12ty4ex {
  width: 100%;
  min-height: 380px;
  max-height: 440px;
  -webkit-background-size: cover;
  background-size: cover;
  background: no-repeat 50px;
  margin-bottom: 2rem;
}


.css-hm6e2f {
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 1rem;
  color: #333333;
}

.css-hm6e2f:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-hm6e2f.active {
  font-weight: bold;
}


.css-1h3gw4a {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 1rem;
  color: #333333;
}

.css-1h3gw4a:hover,
.css-1h3gw4a[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-1h3gw4a:focus-visible,
.css-1h3gw4a[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-1h3gw4a:focus,
.css-1h3gw4a[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-1h3gw4a:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-1h3gw4a.active {
  font-weight: bold;
}


.css-4cbndz {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0 2rem;
}


.css-xid0ch {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
}

@media (max-width: 414px) {
  .css-xid0ch {
    font-size: 2rem;
  }
}


.css-wjgidd {
  color: #666666;
  max-width: 55ch;
  text-align: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 1.25rem;
  margin-top: 1rem;
}


.css-zcsq8w {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  border: var(--eth-borders-1px);
  border-color: var(--eth-colors-text);
  color: var(--eth-colors-text);
  background: var(--eth-colors-transparent);
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-zcsq8w:focus-visible,
.css-zcsq8w[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-zcsq8w[disabled],
.css-zcsq8w[aria-disabled=true],
.css-zcsq8w[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  color: var(--eth-colors-disabled);
  border-color: var(--eth-colors-disabled);
}

.css-zcsq8w:hover,
.css-zcsq8w[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-background);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

.css-zcsq8w:hover[disabled],
.css-zcsq8w[data-hover][disabled],
.css-zcsq8w:hover[aria-disabled=true],
.css-zcsq8w[data-hover][aria-disabled=true],
.css-zcsq8w:hover[data-disabled],
.css-zcsq8w[data-hover][data-disabled] {
  background: initial;
}

.css-zcsq8w:focus,
.css-zcsq8w[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-background);
}

.chakra-button__group[data-attached]>.css-zcsq8w:not(:last-of-type) {
  -webkit-margin-end: -1px;
  margin-inline-end: -1px;
}

.css-zcsq8w:active,
.css-zcsq8w[data-active] {
  background: var(--eth-colors-primaryLight);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}


.css-10ygvai {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  border: var(--eth-borders-1px);
  border-color: var(--eth-colors-text);
  color: var(--eth-colors-text);
  background: var(--eth-colors-transparent);
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-10ygvai:hover,
.css-10ygvai[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-10ygvai:focus-visible,
.css-10ygvai[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-10ygvai:focus,
.css-10ygvai[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-10ygvai:focus-visible,
.css-10ygvai[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-10ygvai[disabled],
.css-10ygvai[aria-disabled=true],
.css-10ygvai[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  color: var(--eth-colors-disabled);
  border-color: var(--eth-colors-disabled);
}

.css-10ygvai:hover,
.css-10ygvai[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-background);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

.css-10ygvai:hover[disabled],
.css-10ygvai[data-hover][disabled],
.css-10ygvai:hover[aria-disabled=true],
.css-10ygvai[data-hover][aria-disabled=true],
.css-10ygvai:hover[data-disabled],
.css-10ygvai[data-hover][data-disabled] {
  background: initial;
}

.css-10ygvai:focus,
.css-10ygvai[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-background);
}

.chakra-button__group[data-attached]>.css-10ygvai:not(:last-of-type) {
  -webkit-margin-end: -1px;
  margin-inline-end: -1px;
}

.css-10ygvai:active,
.css-10ygvai[data-active] {
  background: var(--eth-colors-primaryLight);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}


.css-gwyzyl {
  width: 100%;
  padding: 4rem 0rem;
  margin-top: 2rem;
  background: #fcfcfc;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1);
  padding: 0rem;
  padding-bottom: 4rem;
  margin-top: 0rem;
}


.css-1nnvvha {
  padding: 1rem 2rem;
  width: 100%;
}

@media (max-width: 1024px) {
  .css-1nnvvha {
    padding: 1rem;
  }
}


.css-flbrzu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .css-flbrzu {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin: 0rem;
  }
}


.css-1pvyl3d {
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 75%;
  margin-right: 4rem;
  padding: 6rem;
  height: 100%;
  width: 100%;
  margin: 0;
}

@media (max-width: 1024px) {
  .css-1pvyl3d {
    max-width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 1024px) {
  .css-1pvyl3d {
    padding: 2rem;
  }
}

@media (max-width: 414px) {
  .css-1pvyl3d {
    padding: 0rem;
  }
}


.css-mfhd6j {
  margin: 0 0 1.5rem;
}


.css-r154qs {
  margin-bottom: 2rem;
  font-size: 1.25rem;
  line-height: 140%;
}

@media (max-width: 414px) {
  .css-r154qs {
    font-size: 1rem;
  }
}


.css-1fhs1ox {
  background: "#f1fffd";
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
}

@media (max-width: 1024px) {
  .css-1fhs1ox {
    width: 75%;
  }
}


.css-pw6whm {
  width: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background: no-repeat 50px;
}


.css-cv5bxj {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
  margin: 0rem;
}

@media (max-width: 1024px) {
  .css-cv5bxj {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-template-columns: 1fr;
  }
}


.css-zajvlc {
  min-width: 480px;
  margin: 1rem;
  border-radius: 2px;
  border: 1px solid #333333;
  background: #ffffff;
  box-shadow: 4px 4px 0px 0px #d2d2f9;
}

@media (max-width: 1024px) {
  .css-zajvlc {
    margin: 0;
    min-width: min(100%, 240px);
  }
}


.css-116wtcw {
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-flex: 1 1 372px;
  -ms-flex: 1 1 372px;
  flex: 1 1 372px;
  color: #333333;
  box-shadow: 0px 14px 66px rgba(0, 0, 0, 0.07), 0px 10px 17px rgba(0, 0, 0, 0.03), 0px 4px 7px rgba(0, 0, 0, 0.05);
  margin: 1rem;
  min-width: 480px;
  margin: 1rem;
  border-radius: 2px;
  border: 1px solid #333333;
  background: #ffffff;
  box-shadow: 4px 4px 0px 0px #d2d2f9;
}

.css-116wtcw:hover,
.css-116wtcw:focus {
  -webkit-text-decoration: none;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.15);
  background: #f2f2f2;
  -webkit-transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

@media (max-width: 1024px) {
  .css-116wtcw {
    margin: 0;
    min-width: min(100%, 240px);
  }
}


.css-o8wb81 {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-flex: 1 1 372px;
  -ms-flex: 1 1 372px;
  flex: 1 1 372px;
  color: #333333;
  box-shadow: 0px 14px 66px rgba(0, 0, 0, 0.07), 0px 10px 17px rgba(0, 0, 0, 0.03), 0px 4px 7px rgba(0, 0, 0, 0.05);
  margin: 1rem;
  min-width: 480px;
  margin: 1rem;
  border-radius: 2px;
  border: 1px solid #333333;
  background: #ffffff;
  box-shadow: 4px 4px 0px 0px #d2d2f9;
}

.css-o8wb81:hover,
.css-o8wb81[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-o8wb81:focus-visible,
.css-o8wb81[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-o8wb81:focus,
.css-o8wb81[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-o8wb81:hover,
.css-o8wb81:focus {
  -webkit-text-decoration: none;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.15);
  background: #f2f2f2;
  -webkit-transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

@media (max-width: 1024px) {
  .css-o8wb81 {
    margin: 0;
    min-width: min(100%, 240px);
  }
}


.css-79bps9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  background: radial-gradient(46.28% 66.31% at 66.95% 58.35%, #e6e6f7 0%, #e7edfa 50%, #e9fbfa 100%);
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  min-height: 260px;
}


.css-uwanxk {
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 100px;
  max-width: 372px;
  max-height: 257px;
}

@media (max-width: 414px) {
  .css-uwanxk {
    max-width: 311px;
  }
}


.css-1m3j4vw {
  padding: 1.5rem;
}


.css-6oclq3 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}


.css-13u06cl {
  opacity: 0.8;
  margin-bottom: 0rem;
}


.css-1eih7ak {
  background: #ccfcff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  padding-left: 2rem;
  width: 100%;
  height: 720px;
  margin-top: -1px;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
}

@media (max-width: 1024px) {
  .css-1eih7ak {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 100%;
    padding-top: 2rem;
    padding-left: 0rem;
    padding-bottom: 2rem;
  }
}


.css-1wlq48d {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

@media (max-width: 1024px) {
  .css-1wlq48d {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}


.css-1xu4h82 {
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 75%;
  margin-right: 4rem;
  padding: 6rem;
  height: 100%;
  width: 100%;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .css-1xu4h82 {
    max-width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 1024px) {
  .css-1xu4h82 {
    padding: 2rem;
  }
}


.css-14xqzl7 {
  margin-bottom: 0.5rem;
  font-family: sans-serif;
}

@media (max-width: 414px) {
  .css-14xqzl7 {
    font-size: 1.5rem;
  }
}


.css-1vwde9o {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .css-1vwde9o {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}


.css-af9qpk {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  background: var(--eth-colors-primary);
  color: var(--eth-colors-buttonColor);
  border: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-af9qpk:focus-visible,
.css-af9qpk[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-af9qpk[disabled],
.css-af9qpk[aria-disabled=true],
.css-af9qpk[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  background: var(--eth-colors-disabled);
}

.css-af9qpk:hover,
.css-af9qpk[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-primary);
  opacity: 0.8;
}

.css-af9qpk:hover[disabled],
.css-af9qpk[data-hover][disabled],
.css-af9qpk:hover[aria-disabled=true],
.css-af9qpk[data-hover][aria-disabled=true],
.css-af9qpk:hover[data-disabled],
.css-af9qpk[data-hover][data-disabled] {
  background: var(--eth-colors-gray-100);
}

.css-af9qpk:focus,
.css-af9qpk[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.css-af9qpk:active,
.css-af9qpk[data-active] {
  background: var(--eth-colors-primaryHover);
}


.css-dx5290 {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  background: var(--eth-colors-primary);
  color: var(--eth-colors-buttonColor);
  border: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-dx5290:hover,
.css-dx5290[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-dx5290:focus-visible,
.css-dx5290[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-dx5290:focus,
.css-dx5290[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-dx5290:focus-visible,
.css-dx5290[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-dx5290[disabled],
.css-dx5290[aria-disabled=true],
.css-dx5290[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  background: var(--eth-colors-disabled);
}

.css-dx5290:hover,
.css-dx5290[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-primary);
  opacity: 0.8;
}

.css-dx5290:hover[disabled],
.css-dx5290[data-hover][disabled],
.css-dx5290:hover[aria-disabled=true],
.css-dx5290[data-hover][aria-disabled=true],
.css-dx5290:hover[data-disabled],
.css-dx5290[data-hover][data-disabled] {
  background: var(--eth-colors-gray-100);
}

.css-dx5290:focus,
.css-dx5290[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.css-dx5290:active,
.css-dx5290[data-active] {
  background: var(--eth-colors-primaryHover);
}


.css-7pqxpc {
  gap: 0.5rem;
  margin-top: 0rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 768px) {
  .css-7pqxpc {
    margin-top: 1rem;
    margin-left: 0rem;
  }
}


.css-ir29ch {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  border: var(--eth-borders-1px);
  border-color: var(--eth-colors-text);
  color: var(--eth-colors-text);
  background: var(--eth-colors-transparent);
  -webkit-text-decoration: none;
  text-decoration: none;
  gap: 0.5rem;
  margin-top: 0rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-ir29ch:focus-visible,
.css-ir29ch[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-ir29ch[disabled],
.css-ir29ch[aria-disabled=true],
.css-ir29ch[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  color: var(--eth-colors-disabled);
  border-color: var(--eth-colors-disabled);
}

.css-ir29ch:hover,
.css-ir29ch[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-background);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

.css-ir29ch:hover[disabled],
.css-ir29ch[data-hover][disabled],
.css-ir29ch:hover[aria-disabled=true],
.css-ir29ch[data-hover][aria-disabled=true],
.css-ir29ch:hover[data-disabled],
.css-ir29ch[data-hover][data-disabled] {
  background: initial;
}

.css-ir29ch:focus,
.css-ir29ch[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-background);
}

.chakra-button__group[data-attached]>.css-ir29ch:not(:last-of-type) {
  -webkit-margin-end: -1px;
  margin-inline-end: -1px;
}

.css-ir29ch:active,
.css-ir29ch[data-active] {
  background: var(--eth-colors-primaryLight);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

@media (max-width: 768px) {
  .css-ir29ch {
    margin-top: 1rem;
    margin-left: 0rem;
  }
}


.css-1rhy2us {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  border: var(--eth-borders-1px);
  border-color: var(--eth-colors-text);
  color: var(--eth-colors-text);
  background: var(--eth-colors-transparent);
  -webkit-text-decoration: none;
  text-decoration: none;
  gap: 0.5rem;
  margin-top: 0rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-1rhy2us:hover,
.css-1rhy2us[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-1rhy2us:focus-visible,
.css-1rhy2us[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-1rhy2us:focus,
.css-1rhy2us[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-1rhy2us:focus-visible,
.css-1rhy2us[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-1rhy2us[disabled],
.css-1rhy2us[aria-disabled=true],
.css-1rhy2us[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  color: var(--eth-colors-disabled);
  border-color: var(--eth-colors-disabled);
}

.css-1rhy2us:hover,
.css-1rhy2us[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-background);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

.css-1rhy2us:hover[disabled],
.css-1rhy2us[data-hover][disabled],
.css-1rhy2us:hover[aria-disabled=true],
.css-1rhy2us[data-hover][aria-disabled=true],
.css-1rhy2us:hover[data-disabled],
.css-1rhy2us[data-hover][data-disabled] {
  background: initial;
}

.css-1rhy2us:focus,
.css-1rhy2us[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-background);
}

.chakra-button__group[data-attached]>.css-1rhy2us:not(:last-of-type) {
  -webkit-margin-end: -1px;
  margin-inline-end: -1px;
}

.css-1rhy2us:active,
.css-1rhy2us[data-active] {
  background: var(--eth-colors-primaryLight);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

@media (max-width: 768px) {
  .css-1rhy2us {
    margin-top: 1rem;
    margin-left: 0rem;
  }
}


.css-1d3w5wq {
  width: 100%;
}


.css-s6m2nk {
  background: #ffe3d3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  height: 720px;
  margin-top: -1px;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
}

@media (max-width: 1024px) {
  .css-s6m2nk {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 100%;
    height: 100%;
    padding-top: 2rem;
    padding-right: 0rem;
    padding-bottom: 2rem;
  }
}


.css-1gkje88 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .css-1gkje88 {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}


.css-16mmcnu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}


.css-1r8oiek {
  background: #e1fefa;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  height: 720px;
  margin-top: -1px;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
}

@media (max-width: 1024px) {
  .css-1r8oiek {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 100%;
    height: 100%;
    padding-top: 2rem;
    padding-right: 0rem;
    padding-bottom: 2rem;
  }
}


.css-m9tyng {
  background: #ffe5f9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  padding-left: 2rem;
  height: 720px;
  width: 100%;
  margin-top: -1px;
  margin-bottom: 0rem;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
}

@media (max-width: 1024px) {
  .css-m9tyng {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 100%;
    padding-top: 2rem;
    padding-left: 0rem;
    padding-bottom: 2rem;
  }
}


.css-orwdlu {
  background: #e8e8ff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 720px;
  width: 100%;
  margin-top: -1px;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
}

@media (max-width: 1024px) {
  .css-orwdlu {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 100%;
  }
}


.css-yqw4jm {
  padding: 1rem 2rem;
  width: 100%;
}

@media (max-width: 414px) {
  .css-yqw4jm {
    padding: 1rem;
  }
}


.css-1btqixx {
  margin-left: 4rem;
  max-width: 624px;
  border: 1px solid #333333;
  box-shadow: 4px 4px 0px 0px #d2d2f9;
}

@media (max-width: 1024px) {
  .css-1btqixx {
    margin-left: 0rem;
    max-width: 100%;
  }
}


.css-1x0xxry {
  background-color: #ffffff;
  box-shadow: 0 14px 66px rgba(0, 0, 0, .07), 0 10px 17px rgba(0, 0, 0, .03), 0 4px 7px rgba(0, 0, 0, .05);
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 2px;
  margin-left: 4rem;
  max-width: 624px;
  border: 1px solid #333333;
  box-shadow: 4px 4px 0px 0px #d2d2f9;
}

@media (max-width: 1024px) {
  .css-1x0xxry {
    margin-left: 0rem;
    max-width: 100%;
  }
}


.css-1furgzk {
  background: #f7f7f7;
  padding: 1rem;
  border-bottom: 1px solid #333333;
  font-weight: 600;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}


.css-1pj5dj {
  fill: #b2b2b2;
}

.css-1pj5dj:hover svg {
  fill: #1c1cff;
}

.css-1pj5dj:hover path {
  fill: transparent;
}


.css-d40ir4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 414px) {
  .css-d40ir4 {
    display: none;
  }
}


.css-167z0e3 {
  border-radius: 64px;
  background: #d46666;
  margin-right: 0.5rem;
  width: 12px;
  height: 12px;
}


.css-1d97ohv {
  border-radius: 64px;
  background: #ffe78e;
  margin-right: 0.5rem;
  width: 12px;
  height: 12px;
}


.css-1hcm8dr {
  border-radius: 64px;
  background: #6fc4a0;
  width: 12px;
  height: 12px;
}


.css-kn3oxs {
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  color: #333333 !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1px;
  padding: 1rem;
  width: 100%;
  color: #000000;
}

.css-kn3oxs:hover {
  box-shadow: 0 0 1px #1c1cff;
  background: #d1d1ff;
  color: #000000 !important;
}


.css-mnjd6e {
  -webkit-flex: 1 1 75%;
  -ms-flex: 1 1 75%;
  flex: 1 1 75%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 2rem;
}


.css-1v3tcxd {
  font-size: 0.875rem;
  margin-bottom: 0;
  opacity: 0.7;
}


.css-1jeoay6 .modal-component-container {
  padding: 0;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%;
}

.css-1jeoay6 .modal-component {
  max-width: 100%;
  max-height: 50%;
  padding: 0rem;
}

.css-1jeoay6 .modal-component-content {
  margin-top: 3rem;
  width: 100%;
  overflow: auto;
}


.css-10j6lk1 {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
}


.css-1fci94s {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 2rem 2rem 0;
  border-radius: 2px;
}

@media (max-width: 1024px) {
  .css-1fci94s {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0 0;
  }
}

@media (max-width: 414px) {
  .css-1fci94s {
    margin: 0;
  }
}


.css-1o9wf60 {
  position: relative;
  color: #333333;
  height: 20rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  border: 1px solid;
  padding: 1.5rem;
}

@media (max-width: 1024px) {
  .css-1o9wf60 {
    border-left: 0px solid #000000;
    border-right: 0px solid #000000;
    margin-top: -1px;
    padding: 1rem;
    padding-top: 2rem;
  }
}


.css-fpqowq {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #333333;
  text-transform: uppercase;
  font-family: "SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono", "Liberation Mono", Menlo, Courier, monospace;
}


.css-sdzvux {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 65%;
}


.css-7nakzu {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-family: "SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono", "Liberation Mono", Menlo, Courier, monospace;
}


.css-mm35s4 {
  background: #ffffff;
  font-family: "SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono", "Liberation Mono", Menlo, Courier, monospace;
  font-size: 1.25rem;
  color: #333333;
  padding: 2px 15px;
  border-radius: 1px;
  border: 1px solid;
  cursor: pointer;
  background-color: #e8e8ff;
  opacity: 1;
}

.css-mm35s4:disabled {
  cursor: default;
  opacity: 0.7;
}


.css-dxxej9 {
  background: #ffffff;
  font-family: "SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono", "Liberation Mono", Menlo, Courier, monospace;
  font-size: 1.25rem;
  color: #333333;
  padding: 2px 15px;
  border-radius: 1px;
  border: 1px solid;
  cursor: pointer;
}

.css-dxxej9:disabled {
  cursor: default;
  opacity: 0.7;
}


.css-1kq3h9z {
  position: absolute;
  bottom: 8%;
  font-size: min(4.4vw, 4rem);
  font-weight: 600;
  margin-top: 0rem;
  margin-bottom: 1rem;
  color: #333333;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-overflow: ellipsis;
}

@media (max-width: 1024px) {
  .css-1kq3h9z {
    font-size: max(8.8vw, 48px);
  }
}


.css-1fttcpj {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}


.css-xiby13 {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}


.css-b2sz7e {
  fill: #b2b2b2;
  fill: #333333;
  margin-right: 0.5rem;
}

.css-b2sz7e:hover svg {
  fill: #1c1cff;
}

.css-b2sz7e:hover,
.css-b2sz7e:active,
.css-b2sz7e:focus {
  fill: #1c1cff;
}


.css-ob0hvc {
  min-width: 400px;
  margin: 1rem;
  border-radius: 2px;
  border: 1px solid #333333;
  background: #ffffff;
  box-shadow: 4px 4px 0px 0px #d2d2f9;
}

@media (max-width: 1024px) {
  .css-ob0hvc {
    margin: 0;
    min-width: min(100%, 240px);
  }
}


.css-1aviyuu {
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-flex: 1 1 372px;
  -ms-flex: 1 1 372px;
  flex: 1 1 372px;
  color: #333333;
  box-shadow: 0px 14px 66px rgba(0, 0, 0, 0.07), 0px 10px 17px rgba(0, 0, 0, 0.03), 0px 4px 7px rgba(0, 0, 0, 0.05);
  margin: 1rem;
  min-width: 400px;
  margin: 1rem;
  border-radius: 2px;
  border: 1px solid #333333;
  background: #ffffff;
  box-shadow: 4px 4px 0px 0px #d2d2f9;
}

.css-1aviyuu:hover,
.css-1aviyuu:focus {
  -webkit-text-decoration: none;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.15);
  background: #f2f2f2;
  -webkit-transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

@media (max-width: 1024px) {
  .css-1aviyuu {
    margin: 0;
    min-width: min(100%, 240px);
  }
}


.css-9tk1n5 {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-flex: 1 1 372px;
  -ms-flex: 1 1 372px;
  flex: 1 1 372px;
  color: #333333;
  box-shadow: 0px 14px 66px rgba(0, 0, 0, 0.07), 0px 10px 17px rgba(0, 0, 0, 0.03), 0px 4px 7px rgba(0, 0, 0, 0.05);
  margin: 1rem;
  min-width: 400px;
  margin: 1rem;
  border-radius: 2px;
  border: 1px solid #333333;
  background: #ffffff;
  box-shadow: 4px 4px 0px 0px #d2d2f9;
}

.css-9tk1n5:hover,
.css-9tk1n5[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-9tk1n5:focus-visible,
.css-9tk1n5[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-9tk1n5:focus,
.css-9tk1n5[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-9tk1n5:hover,
.css-9tk1n5:focus {
  -webkit-text-decoration: none;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.15);
  background: #f2f2f2;
  -webkit-transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

@media (max-width: 1024px) {
  .css-9tk1n5 {
    margin: 0;
    min-width: min(100%, 240px);
  }
}


.css-1tjds2i {
  margin: 8rem 0 4rem;
  padding: 2rem 4rem;
}

@media (max-width: 1024px) {
  .css-1tjds2i {
    margin-bottom: 4rem;
    padding: 2rem;
  }
}


.css-73ijvy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  background: linear-gradient(85.12deg, rgba(185, 185, 241, 0.2) 0%, rgba(84, 132, 234, 0.2) 56.29%, rgba(58, 142, 137, 0.2) 99.99%);
  padding: 3rem;
  margin: 1rem;
  margin-top: 6rem;
  margin-bottom: 10rem;
  border-radius: 4px;
  margin: 8rem 0 4rem;
  padding: 2rem 4rem;
}

@media (max-width: 1024px) {
  .css-73ijvy {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1rem;
    margin: 4rem 2rem;
  }
}

@media (max-width: 414px) {
  .css-73ijvy {
    padding: 2rem;
  }
}

@media (max-width: 1024px) {
  .css-73ijvy {
    margin-bottom: 4rem;
    padding: 2rem;
  }
}


.css-8jzn4t {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  width: 100%;
  max-width: 600px;
  margin-top: -6rem;
  margin-bottom: -6rem;
}

@media (max-width: 1024px) {
  .css-8jzn4t {
    margin-bottom: 0rem;
    margin-top: -6rem;
  }
}


.css-1v7g1n4 {
  padding-left: 2rem;
  -webkit-flex: 1 0 50%;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .css-1v7g1n4 {
    margin-top: 2rem;
    padding-left: 1rem;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 414px) {
  .css-1v7g1n4 {
    padding-left: 0;
  }
}


.css-nfx9py {
  margin-top: 0rem;
}


.css-1bzxwjp {
  font-size: 1.25rem;
  width: 90%;
  line-height: 140%;
  margin-bottom: 2rem;
  color: #666666;
}


.css-1s261zq {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--eth-radii-base);
  font-weight: var(--eth-fontWeights-normal);
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-normal);
  height: 42px;
  min-width: var(--eth-sizes-10);
  font-size: var(--eth-fontSizes-md);
  -webkit-padding-start: var(--eth-space-4);
  padding-inline-start: var(--eth-space-4);
  -webkit-padding-end: var(--eth-space-4);
  padding-inline-end: var(--eth-space-4);
  border: var(--eth-borders-1px);
  border-color: var(--eth-colors-text);
  color: var(--eth-colors-text);
  background: var(--eth-colors-transparent);
  -webkit-text-decoration: none;
  text-decoration: none;
  gap: 0.5rem;
  margin-top: 0rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-1s261zq:hover,
.css-1s261zq[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-1s261zq:focus-visible,
.css-1s261zq[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-1s261zq:focus,
.css-1s261zq[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-1s261zq::after {
  content: "↗";
  margin-left: var(--eth-space-0-5);
  margin-right: var(--eth-space-1-5);
}

.css-1s261zq:focus-visible,
.css-1s261zq[data-focus-visible] {
  box-shadow: var(--eth-shadows-outline);
}

.css-1s261zq[disabled],
.css-1s261zq[aria-disabled=true],
.css-1s261zq[data-disabled] {
  opacity: 1;
  cursor: not-allowed;
  box-shadow: var(--eth-shadows-none);
  color: var(--eth-colors-disabled);
  border-color: var(--eth-colors-disabled);
}

.css-1s261zq:hover,
.css-1s261zq[data-hover] {
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--eth-shadows-primary);
  background: var(--eth-colors-background);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

.css-1s261zq:hover[disabled],
.css-1s261zq[data-hover][disabled],
.css-1s261zq:hover[aria-disabled=true],
.css-1s261zq[data-hover][aria-disabled=true],
.css-1s261zq:hover[data-disabled],
.css-1s261zq[data-hover][data-disabled] {
  background: initial;
}

.css-1s261zq:focus,
.css-1s261zq[data-focus] {
  box-shadow: var(--eth-shadows-outline);
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-background);
}

.chakra-button__group[data-attached]>.css-1s261zq:not(:last-of-type) {
  -webkit-margin-end: -1px;
  margin-inline-end: -1px;
}

.css-1s261zq:active,
.css-1s261zq[data-active] {
  background: var(--eth-colors-primaryLight);
  color: var(--eth-colors-primary);
  border-color: var(--eth-colors-primary);
}

@media (max-width: 768px) {
  .css-1s261zq {
    margin-top: 1rem;
    margin-left: 0rem;
  }
}


.css-12lgex3 {
  fill: #b2b2b2;
  fill: #333333;
}

.css-12lgex3:hover svg {
  fill: #1c1cff;
}

.css-12lgex3:hover {
  fill: #1c1cff;
}

.css-12lgex3:active {
  fill: #1c1cff;
}

.css-12lgex3:focus {
  fill: #1c1cff;
}


.css-772gmu {
  padding-top: 3rem;
  padding-bottom: 4rem;
  padding: 1rem 2rem;
}


.css-icjpf3 {
  font-size: 0.875rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}


.css-1lrikuc {
  color: #666666;
}


.css-1oax00r {
  margin: 1rem 0;
}


.css-mjcypk {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-secondary);
}

.css-mjcypk:hover,
.css-mjcypk[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-mjcypk:focus-visible,
.css-mjcypk[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-mjcypk:focus,
.css-mjcypk[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-mjcypk::after {
  margin-left: var(--eth-space-0-5);
  margin-right: var(--eth-space-1-5);
}


.css-1e3y76h {
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 1em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: currentColor;
  font-size: var(--eth-fontSizes-4xl);
  margin-left: var(--eth-space-4);
}


.css-v7r5gs {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 1rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@media (max-width: 1300px) {
  .css-v7r5gs {
    grid-template-columns: repeat(3, auto);
  }
}

@media (max-width: 768px) {
  .css-v7r5gs {
    grid-template-columns: repeat(2, auto);
  }
}

@media (max-width: 500px) {
  .css-v7r5gs {
    grid-template-columns: auto;
  }
}


.css-pkmlk3 {
  font-size: 0.875rem;
  line-height: 1.6;
  margin: 1.14em 0;
  font-weight: bold;
}


.css-jxn0ww {
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 400;
  margin: 0;
  list-style-type: none;
  list-style-image: none;
}


.css-8dk3vg {
  margin-bottom: 1rem;
}


.css-mkqis7 {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #666666;
}

.css-mkqis7 svg {
  fill: #666666;
}

.css-mkqis7:after {
  color: #666666;
}

.css-mkqis7:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-mkqis7:hover:after {
  color: #1c1cff;
}

.css-mkqis7:hover svg {
  fill: #1c1cff;
}


.css-mim3gy {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  white-space: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #666666;
}

.css-mim3gy:hover,
.css-mim3gy[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-mim3gy:focus-visible,
.css-mim3gy[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-mim3gy:focus,
.css-mim3gy[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-mim3gy svg {
  fill: #666666;
}

.css-mim3gy:after {
  color: #666666;
}

.css-mim3gy:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-mim3gy:hover:after {
  color: #1c1cff;
}

.css-mim3gy:hover svg {
  fill: #1c1cff;
}


.css-gjzxq6 {
  transition-property: var(--eth-transition-property-common);
  transition-duration: var(--eth-transition-duration-fast);
  transition-timing-function: var(--eth-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--eth-colors-primary);
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #666666;
}

.css-gjzxq6:hover,
.css-gjzxq6[data-hover] {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.css-gjzxq6:focus-visible,
.css-gjzxq6[data-focus-visible] {
  box-shadow: var(--eth-shadows-none);
  outline: auto;
}

.css-gjzxq6:focus,
.css-gjzxq6[data-focus] {
  box-shadow: var(--eth-shadows-none);
}

.css-gjzxq6::after {
  content: "↗";
  margin-left: var(--eth-space-0-5);
  margin-right: var(--eth-space-1-5);
}

.css-gjzxq6 svg {
  fill: #666666;
}

.css-gjzxq6:after {
  color: #666666;
}

.css-gjzxq6:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #1c1cff;
}

.css-gjzxq6:hover:after {
  color: #1c1cff;
}

.css-gjzxq6:hover svg {
  fill: #1c1cff;
}